import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { BG_COLOR_CHANGE } from './events';

let colorObserver;
let defaultColor;
let bodyClasses;

const rgb2hex = rgb => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`;

const isCustomColor = color => color.startsWith('#');

const getCurrentColor = () => (document.body.style.backgroundColor ? rgb2hex(document.body.style.backgroundColor) : Array.from(bodyClasses).filter(className => className.startsWith('bg-'))[0] || null);

const changeColor = (color = null) => {
    Dispatch.emit(BG_COLOR_CHANGE, { color: color || null });
    const newColor = color || defaultColor;
    const currentColor = getCurrentColor();

    if (newColor === currentColor) {
        return;
    }

    if (isCustomColor(newColor)) {
        bodyClasses.remove(currentColor);
        document.body.style.backgroundColor = newColor;
    } else {
        if (isCustomColor(currentColor)) {
            document.body.style.backgroundColor = null;
        } else {
            bodyClasses.remove(currentColor);
        }
        bodyClasses.add(newColor);
    }
};

const init = () => {

    const colorNodes = Array.from(document.querySelectorAll('[data-color]'));

    bodyClasses = document.body.classList;

    defaultColor = getCurrentColor();

    let prevScrollTop = -99999999999;

    colorObserver = new IntersectionObserver(entries => {

        const { scrollTop } = Viewport;

        const direction = prevScrollTop > scrollTop ? 'up' : 'down';

        prevScrollTop = scrollTop;

        const intersecting = entries.reduce((carry, entry) => (entry.isIntersecting && entry.intersectionRatio >= 0.5 ? carry.concat(entry.target) : carry), []);

        if (intersecting.length) {
            changeColor(intersecting[0].dataset.color);
            return;
        }

        const nodes = direction === 'down' ? [].concat(colorNodes).reverse() : colorNodes;
        for (let i = 0; i < nodes.length; i += 1) {
            if (Viewport.visible(nodes[i])) {
                changeColor(nodes[i].dataset.color);
                return;
            }
        }

        changeColor(null);

    }, {
        //rootMargin: '-10% 0% -10% 0%',
        threshold: [0, 0.5]
    });

    colorNodes.forEach(colorNode => {
        colorObserver.observe(colorNode);
    });

};

const destroy = () => {
    if (colorObserver) {
        colorObserver.disconnect();
        colorObserver = null;
    }
    if (defaultColor) {
        changeColor(defaultColor);
    }
};

export default {
    init,
    destroy
};
