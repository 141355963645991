import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import Viewport from '../core/Viewport';
import shouldAnimate, { reducedMotionQuery } from '../lib/ReducedMotion';

export default el => {

    const dom = $(el);
    const blocksWrapper = el.nextElementSibling;

    let timeline;
    let textHeight = 0;
    let viewportHeight = 0;
    let initialized = false;
    let hasMotionQueryListener = false;

    const destroyTimeline = () => {
        if (timeline) {
            timeline.kill();
        }
    };

    const createTimeline = () => {
        destroyTimeline();
        timeline = gsap.timeline({ paused: true, useFrames: true });
        timeline.to(el, { duration: viewportHeight, filter: 'blur(35px)', scale: 0.9, opacity: 0.3 });
    };

    const updateTextHeight = () => {
        const currentTextHeight = Math.round(el.getBoundingClientRect().height);
        if (currentTextHeight !== textHeight) {
            textHeight = currentTextHeight;
            gsap.set(blocksWrapper, { paddingTop: textHeight });
            return true;
        }
        return false;
    };

    const updateViewportHeight = () => {
        const currentViewportHeight = Viewport.height;
        if (currentViewportHeight !== viewportHeight) {
            viewportHeight = currentViewportHeight;
            return true;
        }
        return false;
    };

    const initialize = () => {
        if (!initialized) {
            dom.addClass('fixed z-0 !mb-0');
            initialized = true;
            updateViewportHeight();
            updateTextHeight();
            createTimeline();
        }
    };

    const onReducedMotionChange = () => {
        if (shouldAnimate()) {
            init();
            onScroll();
        } else {
            destroy();
        }
    };

    const onScroll = () => {
        initialize();
        const progress = Math.max(0, Math.min(window.scrollY / (viewportHeight * 1.5), 1));
        timeline.progress(progress);
    };

    const onResize = () => {
        if (initialized) {
            const viewportHasUpdated = updateViewportHeight();
            const textHasUpdated = updateTextHeight();
            if (viewportHasUpdated || textHasUpdated) {
                createTimeline();
            }
        } else {
            initialize();
        }
        onScroll();
    };

    const init = () => {
        if (shouldAnimate()) {
            Viewport.on('resize', onResize);
            Viewport.on('scroll', onScroll);
        }
        if (!hasMotionQueryListener) {
            hasMotionQueryListener = true;
            reducedMotionQuery.addEventListener('change', onReducedMotionChange);
        }
    };

    const destroy = () => {
        if (initialized) {
            textHeight = 0;
            viewportHeight = 0;
            initialized = false;
            destroyTimeline();
            dom.removeClass('fixed z-0 !mb-0');
            gsap.set([blocksWrapper, el], { clearProps: 'all' });
            Viewport.off('resize', onResize);
            Viewport.off('scroll', onScroll);
        }
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
