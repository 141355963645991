/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const COMPONENT_INIT = 'COMPONENT_INIT';
export const DOM_CHANGED = 'DOM_CHANGED';
export const MENU_CLOSING = 'MENU_CLOSING';
export const MENU_CLOSED = 'MENU_CLOSED';
export const MENU_OPENED = 'MENU_OPENED';
export const MENU_OPENING = 'MENU_OPENING';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';
export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const BG_COLOR_CHANGE = 'BG_COLOR_CHANGE';
