import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import shouldAnimate, { reducedMotionQuery } from '../lib/ReducedMotion';

export default el => {

    const dom = $(el);
    const { body } = document;
    const clickable = $('a, button');
    const cursor = dom.find('[data-cursor]').get(0);

    let prevX = 0;
    let prevY = 0;
    let inited = false;
    let hasMotionQueryListener = false;

    const notTouch = () => document.documentElement.classList.contains('no-touch');

    const setCursorPos = (x, y) => {
        const { top } = body.getBoundingClientRect();
        gsap.set(el, { left: x, top: y - top });
        prevX = x;
        prevY = y;
    };

    const onScroll = () => {
        setCursorPos(prevX, prevY);
    };

    const onMouseEnter = e => {
        // console.log('onMouseEnter');
        gsap.to(cursor, { duration: 0.3, scale: 2.5, backgroundColor: '#FBF680', ease: 'power2.out' });
    };

    const onMouseLeave = e => {
        // console.log('onMouseLeave');
        gsap.to(cursor, { duration: 0.3, scale: 1, backgroundColor:'#000000', ease: 'power2.out' });
    };

    const onMouseMove = e => {
        if (!inited) {
            inited = true;
            $(body).addClass('js-cursor-hidden');
            dom.removeClass('hidden');
            window.addEventListener('scroll', onScroll);
            clickable.on('mouseenter', onMouseEnter);
            clickable.on('mouseleave', onMouseLeave);
        }
        setCursorPos(e.clientX, e.clientY);
    };

    const onReducedMotionChange = e => {
        if (notTouch() && shouldAnimate()) {
            document.addEventListener('mousemove', onMouseMove);
        } else {
            destroy();
        }
    };

    const init = () => {
        if (notTouch() && shouldAnimate()) {
            document.addEventListener('mousemove', onMouseMove);
        }
        if (!hasMotionQueryListener) {
            hasMotionQueryListener = true;
            reducedMotionQuery.addEventListener('change', onReducedMotionChange);
        }
    };

    const destroy = () => {
        if (inited) {
            inited = false;
            dom.addClass('hidden');
            $(body).removeClass('js-cursor-hidden');
            clickable.off('mouseenter', onMouseEnter);
            clickable.off('mouseleave', onMouseLeave);
            document.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('scroll', onScroll);
        }
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
