import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import Revealer from './lib/Revealer';
import BgColor from './lib/BgColor';
import { animatedScroll, isLivePreview } from './lib/helpers';
import shouldAnimate, { reducedMotionQuery } from './lib/ReducedMotion';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();

    BgColor.init();

    $('body').on('click', 'a[href^="#"]', e => {
        e.preventDefault();
        const target = $(e.target.getAttribute('href')).get(0);
        if (!target) {
            return true;
        }
        e.preventDefault();
        animatedScroll(target);
        return false;
    });

    document.addEventListener('lazyloaded', e => {
        const wrapper = $(e.target).parent('.bg-grey-dark');
        if (wrapper.length) {
            setTimeout(() => {
                wrapper.removeClass('bg-grey-dark');
            }, 500);
        }
    });

    const onReduceMotionChange = () => {
        if (shouldAnimate()) {
            if (!isLivePreview()) {
                Revealer.init();
            }
            document.documentElement.classList.remove('reduce-motion');
        } else {
            Revealer.destroy();
            document.documentElement.classList.add('reduce-motion');
        }
    };

    onReduceMotionChange();
    reducedMotionQuery.onchange = onReduceMotionChange;
};

require('doc-ready')(init);
