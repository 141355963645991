import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, MENU_CLOSED, MENU_CLOSING, MENU_OPENED, MENU_OPENING } from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const burger = el.querySelector('button[aria-expanded]');
    const menu = burger.nextElementSibling;
    const pageHeader = document.getElementById('top');

    let isOpen = false;
    let menuTl;

    gsap.set(menu, { opacity: 0 });

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        burger.setAttribute('aria-expanded', 'true');
        document.body.classList.remove('menu-closing');
        document.body.classList.add('menu-opening');
        menu.classList.remove('hidden');
        Dispatch.emit(MENU_OPENING);
        if (menuTl) {
            menuTl.kill();
        }
        gsap.killTweensOf([menu]);
        menuTl = gsap.timeline({
            onComplete() {
                document.body.classList.remove('menu-opening');
                document.body.classList.add('menu-open');
                Dispatch.emit(MENU_OPENED);
            }
        })
            .to(menu, {
                opacity: 1,
                duration: 0.3
            }, 0);
        Viewport.lockTabbing(pageHeader, burger);
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        burger.setAttribute('aria-expanded', 'false');
        document.body.classList.remove('menu-open');
        document.body.classList.remove('menu-opening');
        document.body.classList.add('menu-closing');
        Viewport.releaseTabbing(burger);
        Dispatch.emit(MENU_CLOSING);
        const afterClose = () => {
            document.body.classList.remove('menu-closing');
            Dispatch.emit(MENU_CLOSED);
            menu.classList.add('hidden');
        };
        if (menuTl) {
            menuTl.kill();
            menuTl = null;
        }
        gsap.killTweensOf([menu]);
        if (tween) {
            menuTl = gsap.timeline()
                .to(menu, {
                    opacity: 0,
                    duration: 0.3,
                    onComplete: afterClose
                }, 0);
        } else {
            afterClose();
        }
    };

    const toggle = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape' || key === 27) {
            close();
        }
    };

    const init = () => {
        burger.addEventListener('click', toggle);
        document.body.addEventListener('keyup', onBodyKeyUp);
    };

    const destroy = () => {
        burger.removeEventListener('click', toggle);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        if (isOpen) {
            close(false);
        }
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
