import $ from '../core/Dom';

export default el => {
    const dom = $(el);

    let loaded = false;

    const onTimeUpdate = () => {
        if (el.currentTime < 0.001) {
            return;
        }
        el.removeEventListener('timeupdate', onTimeUpdate);
        dom.removeClass('opacity-0');
        $(el.previousElementSibling).addClass('opacity-0');
    };

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            console.log('intersecting');
            if (loaded) {
                el.play();
            } else {
                loaded = true;
                el.addEventListener('timeupdate', onTimeUpdate);
                dom.attr('src', dom.data('src'));
            }
        } else {
            console.log('not intersecting');
            if (loaded) {
                el.pause();
            }
        }
    };

    const init = () => {
        if (!dom.data('src')) {
            return;
        }
        const observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
    };

    const destroy = () => {
        el.removeEventListener('timeupdate', onTimeUpdate);
    };

    return {
        init,
        destroy
    };
};
