import gsap from 'gsap';
import { loadFlickity } from '../lib/async-bundles';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props = {}) => {

    const $el = $(el);
    const slider = $el.find('[data-slider]').get(0) || el;
    const nav = $el.find('[data-nav]').get(0);
    const prevBtn = nav ? nav.querySelector('[data-nav="prev"]') : null;
    const nextBtn = nav ? nav.querySelector('[data-nav="next"]') : null;
    const counters = $el.find('[data-counter]').get();

    let flkty;

    let Flickity;

    const updateCounter = () => {
        if (!counters.length || !flkty) {
            return;
        }
        counters.forEach(counter => {
            counter.textContent = flkty.selectedIndex + 1;
        });
    };

    const updateNav = () => {
        if (!nav || !flkty) {
            return;
        }
        const {
            size,
            slideableWidth
        } = flkty;
        if (size.width >= slideableWidth) {
            // Hide the nav completely if the slider isn't... slideable.
            nav.hidden = true;
            return;
        }
        nav.hidden = false;
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
        $(el)
            .off('click');
        $(slider)
            .off('focusin');
    };

    const createFlickity = () => {

        destroyFlickity();

        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            groupCells: true,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            resize: false,
            adaptiveHeight: false,
            setGallerySize: false,
            wrapAround: false,
            accessibility: true,
            on: {
                resize() {
                    updateNav();
                },
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                },
                select() {
                    updateCounter();
                    this.cells.forEach(({ element }) => {
                        element.removeAttribute('aria-hidden');
                    });
                    if (prevBtn && nextBtn) {
                        if (!this.options.wrapAround && this.selectedIndex <= 0) {
                            prevBtn.setAttribute('aria-disabled', 'true');
                            prevBtn.setAttribute('tabindex', '-1');
                        } else {
                            prevBtn.removeAttribute('aria-disabled');
                            prevBtn.removeAttribute('tabindex');
                        }
                        if (!this.options.wrapAround && this.selectedIndex >= this.slides.length - 1) {
                            nextBtn.setAttribute('aria-disabled', 'true');
                            nextBtn.setAttribute('tabindex', '-1');
                        } else {
                            nextBtn.removeAttribute('aria-disabled');
                            nextBtn.removeAttribute('tabindex');
                        }
                    }
                },
                ready() {
                    this.resize();
                }
            },
            ...props
        });

        $(slider)
            .on('focusin', 'a', e => {
                slider.parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = flkty.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const index = flkty.cells.indexOf(cell);
                if (index > -1) {
                    flkty.selectCell(index);
                }
            });

        $(el)
            .on('click', '[data-nav="prev"]', () => {
                flkty.previous();
            });

        $(el)
            .on('click', '[data-nav="next"]', () => {
                flkty.next();
            });

        updateNav();

        console.log('flickity created');

    };

    const onResize = () => {
        if (!flkty) {
            return;
        }
        setTimeout(() => {
            flkty.resize();
        }, 0);
    };

    const destroy = () => {
        destroyFlickity();
        Viewport.off('resize', onResize);
    };

    const init = () => {
        loadFlickity(module => {
            Flickity = module.default;
            createFlickity();
        });
        Viewport.on('resize', onResize);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };

};
