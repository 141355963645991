import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const button = $(el);
    const wrapper = el.nextElementSibling;
    const filterGroups = $(wrapper).find('[data-filter-group]').nodes;

    let isOpen = button.attr('aria-expanded') === 'true';

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        button.attr('aria-expanded', 'true');
        // gsap.fromTo(wrapper, { duration: 0.3, ease: 'sine.inOut', height: 0 }, { height: 'auto' });
        // gsap.fromTo(filterGroups, { delay: 0.15, duration: 0.3, ease: 'sine.inOut', y: -30 }, { y: 0 });
    };

    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        button.attr('aria-expanded', 'false');

        /* gsap.to(wrapper, { duration: 0.3, ease: 'sine.out', height: 0 });
        gsap.to(filterGroups, {
            duration: 0.3,
            ease: 'sine.out',
            y: -30,
            onComplete: () => {
                button.attr('aria-expanded', 'false');
            }
        }); */
    };

    const toggle = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape' || key === 27) {
            close();
        }
    };

    const init = () => {
        button.on('click', toggle);
        document.body.addEventListener('keyup', onBodyKeyUp);
    };

    const destroy = () => {
        button.off('click', toggle);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        if (isOpen) {
            close();
        }
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
